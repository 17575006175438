<template>
  <div>
    <v-autocomplete
      dense
      v-model="brand"
      :items="entries"
      :loading="isLoading"
      no-filter
      :search-input.sync="search"
      color="white"
      hide-no-data
      hide-selected
      item-text="label"
      item-value="id"
      placeholder="Search lens brands"
      return-object
      clearable
      auto-select-first
      @change="search = null"
    >
    </v-autocomplete>
  </div>
</template>

<script>
// import Name from "../dir/file";
import debounce from "debounce";
import axios from "axios";

export default {
  name: "LensPanel",
  props: {
    eye: {
      type: String,
      default: "od",
      required: true
    }
  },
  data: function() {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      brand: null,
      search: null
    };
  },
  methods: {
    callAPI(val) {
      this.isLoading = true;
      axios
        .get("https://eyedock.com/search2.php?scl=1&term=" + val)
        .then(response => {
          this.entries = response.data;
          this.isLoading = false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading = false;
        });
    }
  },
  computed: {},

  watch: {
    search(val) {
      if (!val || this.isLoading) return;
      debounce(this.callAPI(val), 100);
    },
    brand(val) {
      this.$emit("brandSelected", val);
      // this.brand = null;
    }
  }
};
</script>

<style scoped></style>
