<template>
  <div>
    <div class="font-weight-medium">Suggestions</div>
    <div v-for="(lens, index) in familyLenses" :key="index">
      <div class="d-flex flex-row clickable mt-3" @click="lensChoice(lens)">
        <img :src="imagePath + lens.image" class="lensImg  mr-2 width25" />
        <div class="width75 mx-1">{{ lens.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Name from "../dir/file";

export default {
  name: "FamilyLensSelector",
  props: {
    eye: {
      type: String,
      default: "od",
      required: true
    },
    priceData: {
      // will need to extract family data: priceData[eye][meta][familyLenses]
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: function() {
    return {
      imagePath: "https://www.eyedock.com/modules/Lenses/pnimages/lens_images/"
    };
  },
  methods: {
    lensChoice(lens) {
      this.$emit("brandSelected", lens);
    }
  },
  computed: {
    familyLenses: function() {
      let otherEye = this.eye == "od" ? "os" : "od";
      // console.log(this.priceData);
      // there's gotta be a better way . . .
      if (!this.priceData) return false;
      if (!this.priceData[otherEye]) return false;
      if (!this.priceData[otherEye].meta) return false;
      if (!this.priceData[otherEye].meta.familyLenses) return false;
      return this.priceData[otherEye].meta.familyLenses;
    }
  }
};
</script>

<style scoped>
.lensImg {
  max-width: 50px;
  max-height: 30px;
}
.clickable {
  cursor: pointer;
}
.width75 {
  width: 75%;
}
.width25 {
  width: 25%;
}
</style>
