<template>
  <div class=" d-flex flex-column align-center">
    <div>
      <div class="d-flex flex-row ma-3">
        <div>
          <div class="d-flex flex-row align-center">
            <div class="px-2">Insurance contribution</div>
            <v-text-field
              v-model="insurance"
              label="Amount"
              value=""
              prefix="$"
              class="px-3"
            ></v-text-field>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row my-5">
        <eye-price-panel
          eye="od"
          :priceData="priceData"
          @brandChange="brand.od = $event"
          @priceDataChange="priceData.od = $event"
          @packageUpdate="fillPackageOption('od', $event)"
        ></eye-price-panel>

        <eye-price-panel
          eye="os"
          :priceData="priceData"
          @brandChange="brand.os = $event"
          @priceDataChange="priceData.os = $event"
          @packageUpdate="fillPackageOption('os', $event)"
        ></eye-price-panel>

        <div>
          <div class="text-h5 mx-2">Calculate</div>

          <v-card class="pane middlePane ma-1 pa-0" flat>
            <v-card-text class="pa-1 d-flex flex-column">
              <div class="greenBkg pa-2">
                <!-- <div class="body-1 my-1">Right Eye</div> -->
                <div
                  class="d-flex flex-row align-center  justify-space-between"
                >
                  <v-text-field
                    v-model="qty.od"
                    label="Packs"
                    class="smallNum"
                  ></v-text-field>
                  <v-text-field
                    v-model="amt.od"
                    label="Amount/pack"
                    value=""
                    prefix="$"
                    class="medNum"
                  ></v-text-field>
                  <div class="text-h6 medNum">
                    {{ (qty.od * amt.od) | currency }}
                  </div>
                </div>
              </div>

              <div class="blueBkg pa-2">
                <!-- <div class="body-1 my-1">Left Eye</div> -->
                <div class="d-flex flex-row align-center justify-space-between">
                  <v-text-field
                    v-model="qty.os"
                    label="Packs"
                    class="smallNum"
                  ></v-text-field>
                  <v-text-field
                    v-model="amt.os"
                    label="Amount/pack"
                    value=""
                    prefix="$"
                    class="medNum"
                  ></v-text-field>
                  <div class="text-h6 medNum">
                    {{ (qty.os * amt.os) | currency }}
                  </div>
                </div>
              </div>

              <div
                class="d-flex flex-row align-center justify-space-between my-2 pa-2"
              >
                <div class="body-1">Ins. Contribution</div>
                <div>-</div>
                <div class="text-h6 totalDiv">- {{ insurance | currency }}</div>
              </div>

              <v-divider></v-divider>
              <div
                class="text-body-1 d-flex flex-row align-center justify-end mt-2 pa-2"
              >
                <div class="mr-2">Subtotal</div>
                <div>
                  {{
                    (qty.od * amt.od + qty.os * amt.os - insurance) | currency
                  }}
                </div>
              </div>
              <v-divider></v-divider>

              <div
                class="d-flex flex-row align-center justify-space-between my-2 pa-2"
              >
                <div class="body-1">Rebate</div>
                <v-text-field
                  v-model="rebate"
                  label=""
                  value=""
                  prefix="$"
                  class="medNum"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </div>

              <div
                class="d-flex flex-row align-center justify-space-between my-2 pa-2"
              >
                <div class="body-1">Other</div>
                <v-text-field
                  v-model="otherAdj"
                  label=""
                  value=""
                  prefix="$"
                  class="medNum"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </div>

              <v-divider></v-divider>
              <div
                class="text-h4 d-flex flex-row align-center justify-end mt-2 pa-2"
              >
                <div class="mr-2">Total</div>
                <div>
                  {{
                    (qty.od * amt.od +
                      qty.os * amt.os -
                      insurance -
                      rebate -
                      otherAdj)
                      | currency
                  }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LensSearch from "../components/LensSearch";
// import FamilyLensSelector from "../components/FamilyLensSelector";
// import axios from "axios";
import EyePricePanel from "../components/EyePricePanel.vue";
// import LensPriceDetails from "../components/LensPriceDetails";

export default {
  name: "Home",
  components: {
    EyePricePanel
  },
  data: () => ({
    // brand_od: null,
    // brand_os: null,
    brand: { od: null, os: null },
    priceData: { od: null, os: null },
    isLoading: { od: false, os: false },
    qty: { od: null, os: null },
    amt: { od: null, os: null },
    insurance: 0,
    rebate: 0,
    otherAdj: 0,
    imagePath: "https://www.eyedock.com/modules/Lenses/pnimages/lens_images/"
  }),

  computed: {},

  methods: {
    fillPackageOption(eye, pkgObj) {
      this.qty[eye] = pkgObj.qty;
      this.amt[eye] = pkgObj.amt;
    }
  },

  watch: {}
};
</script>

<style>
.pane {
  padding: 1em;
  margin-top: 1em;
  min-height: 300px;
}
.sidePane {
  width: 350px;
}
.middlePane {
  width: 350px;
}
.totalDiv {
  min-width: 50px;
}
.fullWidth {
  max-width: 90%;
  width: 1150px;
}
.smallNum {
  max-width: 18%;
}
.medNum {
  max-width: 30%;
}
.greenBkg {
  background-color: #ecffed;
}

.blueBkg {
  background-color: #e0f1ff;
}
</style>
