<template>
  <div v-if="priceData && priceData[eye]">
    <div class="overline">Compare to Online</div>
    <div class="d-flex flex-column">
      <div v-if="priceData[eye] && priceData[eye].online">
        <div v-if="priceData[eye].online" class="font-weight-medium">
          Source: {{ priceData[eye].online[0].source }}
          <a :href="priceData[eye].online[0].link" target="_blank">
            <v-icon small>mdi-open-in-new</v-icon>
          </a>
        </div>
        <div v-for="(row, index) in priceData[eye].online" :key="index">
          {{ row.price | currency }}/{{ row.quantity }}-pack
          <span class="caption ml-2">{{ row.price_text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Name from "../dir/file";

export default {
  name: "OnlinePriceDetails",
  props: {
    eye: {
      type: String,
      default: "od",
      required: true
    },
    priceData: {
      // will need to extract family data: priceData[eye][meta][familyLenses]
      type: Object,
      default: function() {
        return null;
      }
    }
  },
  data: function() {
    return {};
  },
  methods: {},
  computed: {}
};
</script>

<style scoped></style>
