<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <div class="text-h5">EyeDock Contact Lens Price Calculator</div>
      </div>
      <v-spacer></v-spacer>

      <div>
        Practice ID: <input id="practice_id_input" type="number" value="0" />
      </div>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  })
};
</script>
<style scoped>
#practice_id_input {
  width: 40px;
  background-color: aliceblue;
  text-align: center;
}
</style>
