<template>
  <div>
    <div class="text-h5 mx-2">{{ eyeText }} Eye</div>

    <v-card
      class="pane sidePane ma-1"
      :color="eye == 'od' ? '#ecffed' : '#e0f1ff'"
      flat
      outlined
    >
      <div
        class="d-flex flex-row align-start justify-space-between"
        v-if="brand"
      >
        <img
          v-if="brand.image"
          :src="imagePath + brand.image"
          class="lensImg mr-2 my-1"
        />

        <div class="text-h6 brandName mx-1">
          {{ brand.name }}
        </div>
        <v-btn icon color="red" @click="brand = null" class="">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div>
        <lens-search
          :eye="eye"
          v-on:brandSelected="brand = $event"
          v-show="!brand"
        ></lens-search>

        <family-lens-selector
          v-show="brand == null && priceDataForOtherEye != null"
          :eye="eye"
          :priceData="priceData"
          v-on:brandSelected="brand = $event"
        ></family-lens-selector>

        <lens-price-details
          :brand="brand"
          :priceData="priceData[eye]"
          v-show="brand != null"
          @packageUpdate="$emit('packageUpdate', $event)"
        ></lens-price-details>
      </div>
    </v-card>
    <online-price-details
      :eye="eye"
      :priceData="priceData"
    ></online-price-details>
  </div>
</template>

<script>
// import Name from "../dir/file";
import LensSearch from "./LensSearch";
import LensPriceDetails from "./LensPriceDetails";
import FamilyLensSelector from "./FamilyLensSelector";
import axios from "axios";
import OnlinePriceDetails from "./OnlinePriceDetails.vue";

export default {
  name: "EyePricePanel",
  components: {
    LensSearch,
    LensPriceDetails,
    FamilyLensSelector,
    OnlinePriceDetails
  },
  props: {
    eye: String,
    priceData: {
      // will need to extract family data: priceData[eye][meta][familyLenses]
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: function() {
    return {
      priceDataApi: "https://eyedock.com/bcse/api/lens/prices/",
      imagePath: "https://www.eyedock.com/modules/Lenses/pnimages/lens_images/",
      brand: null,
      isLoading: false
      //selectedpackage: { od: null, os: null }, // will include unit price & quantity
    };
  },

  computed: {
    eyeText: function() {
      return this.eye == "od" ? "Right" : "Left";
    },
    otherEye: function() {
      return this.eye == "od" ? "os" : "od";
    },
    priceDataForOtherEye: function() {
      return this.priceData[this.otherEye];
    }
  },

  methods: {
    callDataAPI(val) {
      this.isLoading = true;
      axios
        .get(this.priceDataApi + val.id)
        .then(response => {
          // console.log(response.data);
          //this.priceData = response.data;
          this.$emit("priceDataChange", response.data);
          this.isLoading = false;
        })
        .catch(e => {
          console.log(e);
          this.isLoading = false;
        });
    }
    // packageUpdate(pkgObj) {
    //     this.selectedpackage[this.eye] = pkgObj;
    //     this.$emit('packageUpdate', this.selectedpackage);
    // }
  },

  watch: {
    brand(val) {
      this.$emit("brandChange", val);
      this.callDataAPI(val);
    }
  }
};
</script>

<style scoped>
/* .brandName {
  max-width: 80%;
} */
.lensImg {
  max-width: 20%;
  max-height: 40px;
}
</style>
