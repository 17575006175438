<template>
  <div class="my-2" v-if="priceData">
    <div class="section d-flex flex-row justify-space-between align-center">
      <div class="text-body-1">
        {{ replaceText(priceData.meta.replace_simple) }} Replacement
      </div>
    </div>

    <div class="section">
      <div class="sectionTitle">Package Options</div>
      <div class="d-table">
        <div
          v-for="(qty, index) in priceData.meta.quantities"
          :key="index"
          class="d-table-row"
        >
          <div class="d-table-cell pa-1">
            {{ getPriceForQuantity(qty) | currency }}
          </div>

          <div class="d-table-cell pa-1">
            / {{ qty }} pack
            <span class="caption ml-2">{{ approxDuration(qty) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="sectionTitle">Quick Supply Options</div>

      <span
        v-for="(row, months, index) in priceData.practice.supplies"
        :key="index + 'M'"
        class="pa-1"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              rounded
              class="ma-1"
              v-bind="attrs"
              v-on="on"
              @click="
                $emit('packageUpdate', { qty: row.boxes, amt: row.unitPrice })
              "
              >{{ months }} Mo.
            </v-btn>
          </template>
          <span>
            {{ row.boxes }} x {{ row.unitPrice | currency }}/{{ row.packSize
            }}{{ row.boxes > 1 ? "-packs" : "-pack" }} =
            {{ row.price | currency }}
          </span>
        </v-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
// import Name from "../dir/file";

export default {
  name: "LensPriceDetails",
  props: {
    priceData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    brand: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: function() {
    return {};
  },
  methods: {
    replaceText: function(days) {
      days = parseInt(days);
      if (!days) return null;
      // make this use ranges (>,<) and not absolute numbers
      if (days == 1) return "Daily";
      if (days == 7) return "1 Week";
      if (days == 14) return "2 Week";
      if (days == 30) return "1 Month";
      if (days == 90) return "3 Month";
      if (days == 180) return "6 Month";
      if (days == 365) return "1 Year";
      return days + " Days";
    },
    approxDuration: function(qty) {
      let duration = this.getQuantityInfo(qty, "approxDuration");
      return duration ? ` (${duration}) ` : "";
    },

    getPriceForQuantity(qty) {
      let price = this.getQuantityInfo(qty, "retail");
      return price ? price : " ? ";
    },
    getQuantityInfo(qty, parameter) {
      if (!this.priceData) return null;
      if (!this.priceData.practice) return null;
      if (!this.priceData.practice.boxes) return null;
      let path = this.priceData.practice.boxes;
      if (!path[qty]) return null;
      if (!path[qty][parameter]) return null;
      return path[qty][parameter];
    }

    // todo - use a global filter instead
    // formatPrice(value) {
    //   let val = "$" + (value / 1).toFixed(2);
    //   return val;
    // },
  },
  computed: {}
};
</script>

<style scoped>
.section {
  margin: 1em 0;
}
.sectionTitle {
  border-bottom: 1px spold gray;
  width: 100%;
  font-size: 0.75rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.1666666667em !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase !important;
}
</style>
